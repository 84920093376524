import React, { useEffect } from "react"
import { Form } from "./form"
import HS from "react-hubspot-form"
import PropTypes from "prop-types"

export const HubspotForm = ({ portalId, formId, onSubmit }) => {
  useEffect(() => {
    // This is a workaround to handle the HubSpot form submission event
    // HubSpot usually uses Jquery to handle the form submission event
    function handler(event) {
      if (
        event.data.type === "hsFormCallback" &&
        event.data.eventName === "onFormSubmitted"
      ) {
        // Could remove this check if we want to handle all form submissions
        // But seems like a good idea to check the form ID
        if (event.data.id === formId) {
          onSubmit()
        }
      }
    }

    window.addEventListener("message", handler)
    return () => {
      window.removeEventListener("message", handler)
    }
  }, [])

  return (
    <Form>
      <HS portalId={portalId} formId={formId} />
    </Form>
  )
}

HubspotForm.propTypes = {
  portalId: PropTypes.string,
  formId: PropTypes.string,
  onSubmit: PropTypes.func,
}
