import Flex from "@components/elements/flex"
import Span from "@components/elements/span"
import React from "react"
import PropTypes from "prop-types"
import ReactCountdown from "react-countdown"
import Heading from "@components/elements/heading"
import Paragraph from "@components/elements/paragraph"

const CountdownDigit = ({ digit, title }) => {
  return (
    <Flex>
      <Span fontWeight={700} color="white" fontSize="3rem" lineHeight="1.2">
        {String(digit).padStart(2, "0")}
      </Span>
      <Span color="rgba(255, 255, 255, 0.7)" fontSize="0.9rem">
        {title}
      </Span>
    </Flex>
  )
}

CountdownDigit.propTypes = {
  digit: PropTypes.number,
  title: PropTypes.string,
}

const CountdownFormat = ({ days, hours, minutes, seconds, completed }) => {
  return completed ? (
    <Flex>
      <Heading color="white" as="h3">
        Get access now
      </Heading>
      <Paragraph color="white" margin="0 0 1rem" lineHeight="140%">
        Learn how to kickstart a future-proof metrics strategy with
        PowerMetrics
      </Paragraph>
    </Flex>
  ) : (
    <Flex
      width="min-content"
      flexFlow="row"
      margin="1rem auto 2rem"
      gap="0.15rem"
    >
      <CountdownDigit digit={days} title="days" />
      <Span color="white" fontSize="3rem" lineHeight="1.2">
        :
      </Span>
      <CountdownDigit digit={hours} title="hours" />
      <Span color="white" fontSize="3rem" lineHeight="1.2">
        :
      </Span>
      <CountdownDigit digit={minutes} title="minutes" />
      <Span color="white" fontSize="3rem" lineHeight="1.2">
        :
      </Span>
      <CountdownDigit digit={seconds} title="seconds" />
    </Flex>
  )
}

CountdownFormat.propTypes = {
  days: PropTypes.number,
  hours: PropTypes.number,
  minutes: PropTypes.number,
  seconds: PropTypes.number,
  completed: PropTypes.bool,
}

export const Countdown = ({ date }) => {
  return (
    <ReactCountdown
      date={new Date(date)}
      renderer={props => <CountdownFormat {...props} />}
    />
  )
}

Countdown.propTypes = {
  date: PropTypes.string,
}
