export function processWebinarDate(webinarDate) {
  const date = new Date(webinarDate)

  const options = {
    month: "short", // Abbreviated month name
    day: "numeric", // Day of the month
    year: "numeric", // Full year
    hour: "numeric", // Hour
    minute: "numeric", // Minutes
    hour12: true, // 12-hour format with AM/PM
  }

  const edtTime = date.toLocaleString("en-US", {
    ...options,
    timeZone: "America/New_York",
  })
  const pdtTime = date.toLocaleString("en-US", {
    ...options,
    timeZone: "America/Los_Angeles",
  })
  const cestTime = date.toLocaleString("en-US", {
    ...options,
    timeZone: "Europe/Berlin",
  })

  // Extract the day of the month
  const day = date.getDate()

  // Format the date with ordinal suffix
  const month = date.toLocaleString("en-US", { month: "short" })
  const year = date.getFullYear()
  const formattedDate = `${month} ${day}${getOrdinalSuffix(day)}, ${year}`

  // Extract time parts and timezones
  const edtFormattedTime = edtTime.split(", ")[1].replace(" ", "") + " EDT"
  const pdtFormattedTime = pdtTime.split(", ")[1].replace(" ", "") + " PDT"
  const cestFormattedTime = cestTime.split(", ")[1].replace(" ", "") + " CEST"

  return `${formattedDate} | ${edtFormattedTime} / ${pdtFormattedTime} / ${cestFormattedTime}`
}

// Function to add ordinal suffix (st, nd, rd, th) to day number
function getOrdinalSuffix(day) {
  const suffixes = ["st", "nd", "rd", "th"]
  const remainder = day % 10
  return day < 10 || day > 20
    ? remainder === 1
      ? suffixes[0]
      : remainder === 2
      ? suffixes[1]
      : suffixes[3]
    : suffixes[2]
}
