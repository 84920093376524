import React, { useCallback, useEffect, useState } from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import Paragraph from "@components/elements/paragraph"
import List from "@components/elements/list"
import Heading from "@components/elements/heading"
import Span from "@components/elements/span"
import Div from "@components/elements/div"
import Grid from "@components/elements/grid"
import { TestimonialUser } from "@components/common/testimonial"
import Button from "@components/elements/button"
import Layout from "@components/layout"
import Flex from "@components/elements/flex"
import { HighGradient } from "@components/common/gradient"
import { useGradient } from "@hooks/use-gradient"
import { CtaContainer } from "@components/cta/colourful/styled"
import { PRODUCTS } from "@hooks/constants"
import { Countdown } from "@components/common/countdown"
import Image from "@components/image"
import { PowerMetricsEOPCta } from "@components/cta/eop/powermetrics"
import { Sticky } from "@components/common/article"
import { useTheme } from "styled-components"
import Html from "@components/html"
import Modal from "@components/elements/modal"
import { HubspotForm } from "@components/common/hubspot-form"
import { processWebinarDate } from "@utils/process-webinar"
import YouTubeEmbed from "@components/directus/youtube-embed"
import Anchor from "@components/elements/anchor"
import { useCookies } from "react-cookie"

const PARAGRAPH_STYLING = {
  fontSize: "1.1rem",
  lineHeight: "1.7",
}

const FORM_ID = "8d82a34b-9a81-4655-8c6c-9dbcf4e2dbde"

const WebinarTemplate = ({ data }) => {
  const webinar = data.webinar
  const graphic = data.graphic

  const { color } = useTheme()
  const { gradient: imageGradient } = useGradient()
  const date = processWebinarDate(webinar.date)

  const [complete, setComplete] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const toggleModal = useCallback(() => setModalOpen(wasOpen => !wasOpen), [])

  const [cookies, setCookie] = useCookies([`webinar-${webinar.slug}`])

  const submit = () => {
    setModalOpen(false)
    setCookie(`webinar-${webinar.slug}`, "submitted")
  }

  useEffect(() => {
    if (cookies[`webinar-${webinar.slug}`] === "submitted") {
      setSubmitted(true)
    }
  }, [cookies])

  useEffect(() => {
    if(new Date(webinar.date) < new Date()) {
      setComplete(true)
    }
  }, [])

  return (
    <Layout fullWidth marginless seo={webinar.seo}>
      <Modal show={modalOpen} setModalState={setModalOpen}>
        <HubspotForm portalId="7477725" formId={FORM_ID} onSubmit={submit} />
      </Modal>
      {submitted ? (
        <Div position="relative" overflow="clip">
          <HighGradient src={imageGradient.cdn} />
          <Div position="relative" margin="8rem auto 0" container>
            <Heading
              maxWidth="25ch"
              center
              fontsize="3rem"
              margin="0 auto 1rem"
            >
              {webinar.seo.title}
            </Heading>
            <Paragraph center margin="0 auto 3rem">
              Thanks for signing up! We also sent the webinar to your email.
            </Paragraph>
            <YouTubeEmbed id={webinar.embed} />
            <Grid
              columns="repeat(3, 1fr)"
              columnsMd="1fr"
              gap="2rem"
              margin="3rem 0 6rem"
            >
              <Flex gap="1rem">
                <Heading as="h4">Read the documentation</Heading>
                <Paragraph fontSize="1.1rem">
                  Dive into technical documentation to learn how to get started
                  with PowerMetrics.
                </Paragraph>
                <Anchor
                  fontSize="1.1rem"
                  arrow
                  link="https://support.klipfolio.com/hc/en-us/categories/360000361994-PowerMetrics"
                >
                  Read docs
                </Anchor>
              </Flex>
              <Flex gap="1rem">
                <Heading as="h4">Create a free account</Heading>
                <Paragraph fontSize="1.1rem">
                  Start a free account and get access to all premium features
                  for 30 days.
                </Paragraph>
                <Anchor fontSize="1.1rem" arrow link="/powermetrics">
                  Get started
                </Anchor>
              </Flex>
              <Flex gap="1rem">
                <Heading as="h4">Watch more webinars</Heading>
                <Paragraph fontSize="1.1rem">
                  Learn more about Klipfolio with on-demand webinars hosted by
                  Klipfolio experts.
                </Paragraph>
                <Anchor arrow fontSize="1.1rem" link="/resources/webinars">
                  Browse webinars
                </Anchor>
              </Flex>
            </Grid>
          </Div>
        </Div>
      ) : (
        <Div position="relative" overflow="clip">
          <HighGradient src={imageGradient.cdn} />
          <Grid
            position="relative"
            columns="4fr 3fr"
            columnsMd="1fr"
            gap="4rem"
            container
            margin="10rem auto 6rem"
            gapMd="2rem"
          >
            <Flex position="relative">
              {!complete && (
                <Span
                  color={color.indigo400}
                  fontWeight={700}
                  fontSize="1rem"
                  margin="0 0 1rem"
                >
                  Upcoming Webinar:
                </Span>
              )}
              <Heading fontsize="3rem" margin="0 0 1.5rem">
                {webinar.seo.title}
              </Heading>
              {complete ? (
                <Heading as="h4" fontSize="1.2rem" margin="0 0 2rem">
                  Watch on-demand
                </Heading>
              ) : (
                <Heading as="h4" fontSize="1.2rem" margin="0 0 2rem">
                  Join us on {date}
                </Heading>
              )}
              <Flex gap="1rem" fontSize="1.1rem">
                <Html inherit parseElements html={webinar.body} />
              </Flex>
            </Flex>
            <Sticky>
              <CtaContainer
                padding="3rem 2rem"
                product={PRODUCTS.POWERMETRICS}
                minWidth="300px"
                height="max-content"
                show
              >
                <Div
                  zIndex={10}
                  position="relative"
                  padding={complete ? "2rem 0" : null}
                >
                  {!complete && (
                    <Heading
                      fontWeight="500"
                      fontSize="1.15rem"
                      letterSpacing="0"
                      as="h4"
                      color="white"
                    >
                      Webinar starts in
                    </Heading>
                  )}
                  <Countdown date={webinar.date} />
                  {complete && (
                    <Button
                      background={color.indigo400}
                      notice={false}
                      margin="0.5rem 0 0"
                      onClick={toggleModal}
                    >
                      Watch on-demand
                    </Button>
                  )}
                </Div>
              </CtaContainer>
            </Sticky>
          </Grid>
        </Div>
      )}
      {!submitted && (
        <Div
          background={color.indigo100}
          padding="4rem 6rem"
          paddingMd="2rem"
          borderRadius="1rem"
          container
          margin="0 auto 6rem"
        >
          <Flex gap="1rem" margin="0 0 2rem">
            <Html parseElements html={webinar.presenter_body} />
          </Flex>
          <Grid
            margin="0 auto 3rem"
            gap="1.5rem"
            gapMd="1rem"
            columns="repeat(3, 1fr)"
            columnsMd="repeat(2, 1fr)"
          >
            {webinar.presenters.map(presenter => (
              <TestimonialUser
                key={presenter.id}
                image={presenter.headshot}
                multiline
                name={presenter.name}
                title={presenter.title}
                company={presenter.company}
              />
            ))}
          </Grid>
          {complete ? (
            <Button
              background={color.indigo400}
              notice={false}
              margin="0.5rem 0 0"
              onClick={toggleModal}
            >
              Watch on-demand
            </Button>
          ) : (
            <Button.PowerMetrics notice={false} href={webinar.link}>
              Register Now
            </Button.PowerMetrics>
          )}
        </Div>
      )}
      <Grid
        container
        margin="0 auto 6rem"
        gap="2rem"
        columns="2fr 3fr"
        columnsMd="1fr"
      >
        <Div>
          <Heading margin="0 0 1.5rem">Discover PowerMetrics</Heading>
          <Flex gap="1rem">
            <Paragraph {...PARAGRAPH_STYLING}>
              PowerMetrics is a complementary metrics platform that works
              alongside existing BI and analytics tools to provide a solution
              designed for non-technical business users.
            </Paragraph>
            <Paragraph {...PARAGRAPH_STYLING}>
              What makes PowerMetrics different?
            </Paragraph>
            <List gap="0.5rem">
              <li>
                <Paragraph {...PARAGRAPH_STYLING}>
                  Its hybrid integrations approach means you can work with
                  metrics based on any data stack.
                </Paragraph>
              </li>
              <li>
                <Paragraph {...PARAGRAPH_STYLING}>
                  All metrics, regardless of data source, can be used
                  interchangeably in calculations, analysis and visualizations.
                </Paragraph>
              </li>
              <li>
                <Paragraph {...PARAGRAPH_STYLING}>
                  Includes a natural language interface that understands
                  intentions and uses generative AI to build visualizations and
                  provide insights.
                </Paragraph>
              </li>
            </List>
          </Flex>
        </Div>
        <Image objectFit="contain" file={graphic} />
      </Grid>
      <PowerMetricsEOPCta
        heading={"Make analytics everybody's business."}
        description={"Get started with a free account."}
      />
    </Layout>
  )
}

WebinarTemplate.propTypes = {
  data: PropTypes.object.isRequired,
}

export default WebinarTemplate

export const pageQuery = graphql`
  query WebinarTemplate($id: String!) {
    graphic: directusFiles(
      directus_id: { eq: "d1f57c64-14b2-45eb-acc9-765d4abc9662" }
    ) {
      cdn
      id
      placeholder
      title
    }
    webinar: webinars(id: { eq: $id }) {
      seo {
        ...seoData
      }
      link
      date
      body
      body_images {
        cdn
        placeholder
        id
        directus_id
      }
      presenter_body
      presenter_body_images {
        cdn
        placeholder
        id
        directus_id
      }
      presenters {
        id
        name
        title
        company
        headshot {
          cdn
          placeholder
          id
          directus_id
        }
      }
      slug
      embed
    }
  }
`
